<template>
  <div class="py-2"></div>
  <!--    <PrimeCard class="max-w-fit mx-auto bg-primary text-left text-white">-->
  <!--      <template #content>-->
  <!--        <div class="relative py-3 flex flex-wrap justify-content-center">-->
  <!--          <div class="flex justify-content-center align-items-center lg:px-6">-->
  <!--            <NuxtImg format="webp" src="/img/logo-qualiopi-300dpi.webp" alt="Certification Qualiopi"-->
  <!--                     class="h-8rem border-round-lg" loading="lazy"/>-->
  <!--          </div>-->

  <!--          <div class="mt-5 lg:mt-0 text-center lg:text-left">-->
  <!--            <div class="text-xl lg:text-3xl line-height-2">Blockproof (Block I.T) est certifiée Qualiopi</div>-->
  <!--            <div class="mt-5">La certification qualité a été délivrée au titre des catégories d'actions suivantes :-->
  <!--            </div>-->
  <!--            <div>ACTIONS DE FORMATION</div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </template>-->
  <!--    </PrimeCard>-->
</template>

<style scoped>
</style>

<script setup lang="ts">
</script>
